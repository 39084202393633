import React, { useState, useEffect } from 'react';
import image1 from '../../Assests/outlet.jpg'; // Import images directly
import image2 from '../../Assests/g9.jpg'; // Import images directly

const outlets = [
  {
    id: 1,
    city: 'Lucknow',
    area: 'Aliganj',
    images: [image1, image2], // Add more images
  },
  // Add more outlets as needed
];

const Outlet = () => {
  const [currentOutletIndex, setCurrentOutletIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentOutlet, setCurrentOutlet] = useState(outlets[currentOutletIndex]); // Initialize currentOutlet state

  const nextOutlet = () => {
    setCurrentOutletIndex((prevIndex) => (prevIndex + 1) % outlets.length);
    setCurrentImageIndex(0); // Reset image index when changing outlets
  };

  const prevOutlet = () => {
    setCurrentOutletIndex((prevIndex) => (prevIndex - 1 + outlets.length) % outlets.length);
    setCurrentImageIndex(0); // Reset image index when changing outlets
  };

  useEffect(() => {
    setCurrentOutlet(outlets[currentOutletIndex]); // Update currentOutlet when index changes

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % outlets[currentOutletIndex].images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentOutletIndex]);

  return (
    <section className="outlet-section" id="outlet">
      <h2 className="outlet-header">Our Outlets</h2>
      <p className="outlet-description">We are trusted by our franchisees</p>
      <div className="outlet-container">
        <button className="nav-button" onClick={prevOutlet}>&lt;</button>
        <div className="outlet-card">
          <img src={currentOutlet.images[currentImageIndex]} alt={currentOutlet.name} />
          <div className="outlet-card-content">
            <h3>{currentOutlet.name}</h3>
            <p>{currentOutlet.description}</p>
          </div>
        </div>
        <div className="outlet-info">
          <h4>{currentOutlet.city}</h4>
          <h5>{currentOutlet.area}</h5>
        </div>
        <button className="nav-button" onClick={nextOutlet}>&gt;</button>
      </div>
    </section>
  );
};

export default Outlet;
