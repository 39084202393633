import React from 'react';
import image1 from '../../Assests/g1.jpg';
import image3 from '../../Assests/g3.jpg';
import image4 from '../../Assests/g4.jpg';
import image6 from '../../Assests/g6.jpg';
import image7 from '../../Assests/g7.jpg';
import image8 from '../../Assests/g8.jpg';
import image10 from '../../Assests/g10.jpg';
import image11 from '../../Assests/g11.jpg';
import image14 from '../../Assests/g14.jpg';
import image15 from '../../Assests/g15.jpg';

const images = [image1, image3,image4, image6, image7, image8,image10, image11, image14, image15];

const Gallery = () => {
    return (
      <section className="gallery-section" id="gallery">
        <h2>Our Gallery</h2>
        <p>Explore our collection</p>
        <div className="gallery-container">
          {images.map((src, index) => (
            <div className="gallery-card" key={index}>
              <img src={src} alt={`Gallery ${index + 1}`} />
            </div>
          ))}
        </div>
      </section>
    );
  };
  
  export default Gallery;