import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LOGO from '../../Assests/company.png'
const ContactNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    // Close the menu when a link is clicked on larger screens
    if (!isOpen) {
      return; // Do nothing if menu is already closed
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={LOGO} alt="Logo" />
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`} ref={menuRef}>
        <ul>
          <li><a href="/" onClick={handleLinkClick}>Home</a></li>
          <li className="franchise-link"><Link to="/franchise" onClick={handleLinkClick}>Franchise</Link></li>
        </ul>
        {isOpen && (
          <div className="close-icon" onClick={toggleNavbar}>
            &#10005; {/* Unicode for cross icon */}
          </div>
        )}
      </div>
      <div className="navbar-toggle" onClick={toggleNavbar}>
        ☰
      </div>
    </nav>
  );
};

export default ContactNavbar;
