// emailjs.send('nayan_service', 'template_p6gqi2r', formData, '63pPaoioxdxo4m9Px')
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const QueryForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    query: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('biryani_nawaabs_service', 'template_equu6os', e.target, 'bvSaCuZmmo3PUEM8t')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
        e.target.reset();  
        setFormData({ 
          name: '',
          email: '',
          number: '',
          county: '',
          state: '',
          city: '',
          budget: '',
          experience: '',
          query: ''
        });
      }, (error) => {
        console.log(error.text);
        alert('Failed to send the message. Please try again later.');
      });

    e.target.reset();
  };

  return (
    <section className="query-form-section" id="query">
      <div className="query-form-header">
        <h2>Contact Us</h2>
        <p className="query-form-description">We'd love to hear from you. Please fill out the form below.</p>
      </div>
      <div className="query-form-container">
        <form className="query-form" onSubmit={handleSubmit}>
          <div className="query-form-group">
            <div className="form-field">
              <label htmlFor="name">Your Name</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-field">
              <label htmlFor="email">Your Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
          </div>
          <div className="query-form-group">
          <div className="form-field">
              <label htmlFor="number">Your Number</label>
              <input
                type="text"
                id="number"
                name="phone"
                value={formData.number}
                onChange={handleChange}
                pattern="\d{10}"
                maxLength="10"
                minLength="10"
                title="Phone number must be exactly 10 digits."
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="subject">Subject</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
            </div>
          </div>
          <div className="query-form-group">
            <div className="form-field form-field-full">
              <label htmlFor="query">Your Query</label>
              <textarea id="query" name="query" rows="4" value={formData.query} onChange={handleChange} required />
            </div>
          </div>
          <button type="submit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="24" height="24">
              <path d="M2.01 21L23 12 2.01 3v7l15 2-15 2z" fill="currentColor"/>
            </svg>
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default QueryForm;
