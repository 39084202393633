import React from 'react'
import './Homepage.css'
import FranchiseTicker from '../component/Home/FranchiseTicker'
import Navbar from '../component/Home/Navbar'
import WelcomeSection from '../component/Home/WelcomeSection'
import WhyWe from '../component/Home/WhyWe'
import AboutUs from '../component/Home/AboutUs'
import ReviewSection from '../component/Home/ReviewSection'
import Contact from '../component/Home/Contact'
import Gallery from '../component/Home/Gallery'
import QueryForm from '../component/Home/QueryForm'
import Outlet from '../component/Home/Outlet'

export default function Homepage() {
  return (
    <>
    <FranchiseTicker/>
    <Navbar/>
    <WelcomeSection/>
    <WhyWe/>
    <AboutUs/>
    <Outlet/>
    <Gallery/>
    <QueryForm/>
    <ReviewSection/>
    <Contact/>
    </>
  )
}
