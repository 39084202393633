import React, { useEffect, useState } from "react";

const AboutUs = () => {
  const images = [
    require("../../Assests/about1.png"),
    require("../../Assests/about2.png"),
    require("../../Assests/about3.png"),
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="about-us-section" id="about">
      <div
        className="about-us-background"
        style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
      ></div>
      <div className="about-us-content">
        <p>
          Welcome to Biryani Nawaabs! Our story began with a passion for
          authentic flavors and a commitment to quality. Our restaurant has been
          serving delicious biryani and other Indian delicacies to food lovers
          across the region. We believe in using only the finest ingredients and
          traditional cooking methods to bring you a culinary experience like no
          other.
        </p>
        <a
          href="https://www.google.com/maps"
          target="_blank"
          rel="noopener noreferrer"
          className="find-us-link"
        >
          <button className="find-us-button">Find Us on Map</button>
        </a>
      </div>
    </div>
  );
};

export default AboutUs;
