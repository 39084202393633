import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import FranchisePage from './pages/Franchisepage'
import Contactpage from './pages/contactpage';
function App() {
  return (
   <>
   <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/franchise" element={<FranchisePage />} />
        <Route path="/contactpage" element={<Contactpage />} />
      </Routes>
    </Router> 
   </>
  );
}
export default App;
