import React, { useState } from "react";
import emailjs from "emailjs-com";

const FranchiseForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    budget: "",
    experience: "",
    query: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "biryani_nawaabs_service",
        "template_7ucm897",
        e.target,
        "bvSaCuZmmo3PUEM8t"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
          e.target.reset();  
          setFormData({ 
            name: '',
            email: '',
            number: '',
            county: '',
            state: '',
            city: '',
            budget: '',
            experience: '',
            query: ''
          });
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send the message. Please try again later.");
        }
      );

    e.target.reset();
  };

  return (
    <section className="franchise-form-section" id="FranchiseForm">
      <div className="franchise-form-header">
        <h2>Franchise Form</h2>
        <p className="franchise-form-description">
          Join our franchise. Please fill out the form.
        </p>
      </div>
      <div className="franchise-form-container">
        <form className="franchise-form" onSubmit={handleSubmit}>
          <div className="franchise-form-group">
            <div className="form-field">
              <label htmlFor="name">Your Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="franchise-form-group">
            <div className="form-field">
              <label htmlFor="number">Your Number</label>
              <input
                type="text"
                id="number"
                name="phone"
                value={formData.number}
                onChange={handleChange}
                pattern="\d{10}"
                maxLength="10"
                minLength="10"
                title="Phone number must be exactly 10 digits."
                required
              />
            </div>

            <div className="form-field">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="franchise-form-group">
            <div className="form-field">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="franchise-form-group">
            <div className="form-field">
              <label htmlFor="budget">Budget</label>
              <input
                type="number"
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="experience">Prior Experience</label>
              <input
                type="number"
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="franchise-form-group">
            <div className="form-field form-field-full">
              <label htmlFor="query">Your Query</label>
              <textarea
                id="query"
                name="query"
                rows="4"
                value={formData.query}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              width="24"
              height="24"
            >
              <path
                d="M2.01 21L23 12 2.01 3v7l15 2-15 2z"
                fill="currentColor"
              />
            </svg>
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default FranchiseForm;
