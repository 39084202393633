import React from 'react';
const FranchiseTicker = () => {
  const texts = [
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
    "Franchise Enquiry",
  ];

  return (
    <div className="ticker-wrapper">
      <div className="ticker">
        {[...texts, ...texts].map((text, index) => (
          <div key={index} className="ticker-item">
            {text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FranchiseTicker;
