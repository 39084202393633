import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const WelcomeSection = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: ['Hey Nawaabs!', 'Biryani Nawaabs Welcomes You.'],
      typeSpeed: 80,
      backSpeed: 80,
      loop: true,
      showCursor: false,
    };

    const typed = new Typed(typedRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="welcome-section">
      <div className="welcome-content">
        <h1 ref={typedRef}></h1>
        <p>Biryani Nawaabs welcomes you to experience the finest flavors of traditional biryanis.</p>
      </div>
    </div>
  );
};

export default WelcomeSection;
