import React, { useState } from 'react';


const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    { question: 'What is Biryani Nawaabs?', answer: 'Biryani Nawaabs is a restaurant chain specializing in various types of biryani.' },
    { question: 'How can I join the franchise?', answer: 'To join the franchise, fill out the franchise form available on our website.' },
    { question: 'Where are you located?', answer: 'We are located in Aliganj, Lucknow, Uttar Pradesh.' },
    { question: 'Do you offer delivery?', answer: 'Yes, we offer delivery services in select locations.' },
    { question: 'What are your operating hours?', answer: 'Our operating hours are from 11 AM to 10 PM every day.' },
    { question: 'How can I contact customer support?', answer: 'You can contact customer support via email at Biryaninawaabs@gmail.com or call us at ‪+91 70804 62999‬.' },
    { question: 'Do you offer vegetarian options?', answer: 'Yes, we have a variety of vegetarian biryani options available.' },
    { question: 'Can I order online?', answer: 'Yes, you can place orders online through our website.' },
  ];

  return (
    <section className="faq-section" id="Faqs">
      <h2  className="faq-heading">Frequently Asked Questions</h2>
      <p>Find answers to some of the most commonly asked questions below.</p>
      <div className="faq-columns">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              <span>{faq.question}</span>
              <span>{activeIndex === index ? '✕' : '+'}</span>
            </div>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
