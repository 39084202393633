import React from 'react';
import InstagramIcon from '../../Assests/insta.png';

const ContactSection = () => {
  return (
    <div className="contact-page1">
      <h1 className="contact-heading1">Contact Us</h1>
      <p className="contact-paragraph1">We'd love to hear from you! Please find our contact details below.</p>
      
      <div className="contact-details1">
        {/* First row: Location, Web Address, Phone Number */}
        <div className="contact-row1">
          <div className="contact-column1">
            <h2 className="contact-subheading1">Location</h2>
          </div>
          <div className="contact-column1">
            <h2 className="contact-subheading1">Web Address</h2>
          </div>
          <div className="contact-column1">
            <h2 className="contact-subheading1">Phone Number</h2>
          </div>
        </div>
        
        {/* Corresponding content for the first row */}
        <div className="contact-row1">
          <div className="contact-column1">
            <div className="contact-info1">
              <svg className="contact-icon1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffc107" width="24px" height="24px">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 4.68 7 13 7 13s7-8.32 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
              </svg>
              <p>Aliganj, Lucknow</p>
            </div>
          </div>
          <div className="contact-column1">
            <div className="contact-info1">
              <svg className="contact-icon1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffc107" width="24px" height="24px">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
                <path d="M11 14h2v4h-2zm0-10h2v4h-2zm4.53 7.11l-1.42-1.42 2.83-2.83-1.42-1.42-2.83 2.83-1.42-1.42L12 5.17l1.41-1.42 2.83 2.83-1.42 1.42 2.83 2.83-1.42 1.42zM5.41 19.42l1.42-1.42-2.83-2.83 1.42-1.42 2.83 2.83 1.42-1.42L12 18.83l-1.41 1.42-2.83-2.83-1.42 1.42 2.83 2.83z"/>
              </svg>
              <p>biryaninawaabs.in</p>
            </div>
          </div>
          <div className="contact-column1">
            <div className="contact-info1">
              <svg className="contact-icon1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffc107" width="24px" height="24px">
                <path d="M6.62 10.79a15.028 15.028 0 0 0 6.59 6.59l2.2-2.2a1 1 0 0 1 1.1-.27c1.2.45 2.51.69 3.86.69a1 1 0 0 1 1 1v3.5a1 1 0 0 1-1 1C10.52 21 3 13.48 3 4.5a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.35.24 2.66.69 3.86a1 1 0 0 1-.27 1.1l-2.2 2.2z"/>
              </svg>
              <p>‪+91 70804 62999‬</p>
            </div>
          </div>
        </div>

        {/* Second row: Social Media and Email */}
        <div className="contact-row1">
          <div className="contact-column1">
            <h2 className="contact-subheading1">Social Media</h2>
          </div>
          <div className="contact-column1">
            <h2 className="contact-subheading1">Email</h2>
          </div>
        </div>

        {/* Corresponding content for the second row */}
        <div className="contact-row1">
          <div className="contact-column1">
            <div className="contact-info1">
              <a href="https://www.instagram.com/biryaninawaabs.india?igsh=MWN3MW5jd3M1M3NxZQ==" target="_blank" rel="noopener noreferrer">
                <img src={InstagramIcon} alt="Instagram" />
              </a>
            </div>
          </div>
          <div className="contact-column1">
            <div className="contact-info1">
              <svg className="contact-icon1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                <path d="M12 13.4l6.7-4.7H5.3L12 13.4zm-6.7-6.9h13.4c.6 0 1.1.2 1.5.6.4.4.6.9.6 1.5v9c0 .6-.2 1.1-.6 1.5-.4.4-.9.6-1.5.6H5.3c-.6 0-1.1-.2-1.5-.6-.4-.4-.6-.9-.6-1.5v-9c0-.6.2-1.1.6-1.5.4-.4.9-.6 1.5-.6z" fill="#ffc107"/>
              </svg>
              <a href="mailto:Biryaninawaabs@gmail.com">Biryaninawaabs@gmail.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
