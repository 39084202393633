// import image1 from '../../Assests/royalty.png'; 
// import image2 from '../../Assests/marketing.png';
// import image3 from '../../Assests/setup.png';
// import image4 from '../../Assests/franchisefee.png';
// import image5 from '../../Assests/minarea.png';
// import image6 from '../../Assests/property.png';
import React from 'react';
import image1 from '../../Assests/royalty.png'; 
import image2 from '../../Assests/marketing.png';
import image3 from '../../Assests/setup.png';
import image4 from '../../Assests/franchisefee.png';
import image5 from '../../Assests/minarea.png';
import image6 from '../../Assests/property.png';

const cards = [
  { id: 1, textTop: 'Royalty', textBottom: '', image: image1 },
  { id: 2, textTop: 'Marketing', textBottom: '', image: image2 },
  { id: 3, textTop: 'Setup Cost', textBottom: '', image: image3 },
  { id: 4, textTop: 'Franchise Fee', textBottom: '', image: image4 },
  { id: 5, textTop: 'Minimum Area Sq.', textBottom: '', image: image5 },
  { id: 6, textTop: 'Property Lease', textBottom: '', image: image6 },
];

const FranchiseDetails = () => {
  return (
    <section className="franchise-details-section" id="FranchiseDetails">
      <h2>Franchise Details</h2>
      <div className="cards-container">
        {cards.map(card1 => (
          <div key={card1.id} className="card1">
            <div className="card1-text-top">{card1.textTop}</div>
            <img src={card1.image} alt={`Card ${card1.id}`} />
            <div className="card1-text-bottom">{card1.textBottom}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FranchiseDetails;