import React from 'react'
import './contactpage.css'
import './Homepage.css'
import ContactDetails from '../component/Home/conatactus'
import ContactNavbar from '../component/Home/conatactnavbar'

export default function contactpage() {
  return (
    <>
    <ContactNavbar/>
    <ContactDetails/>
    </>
  )
}
