import React, { useState, useEffect } from 'react';


// Import images
import profile1 from '../../Assests/profilepic.png'; // Replace with actual image path
import profile2 from '../../Assests/profilepic.png'; // Replace with actual image path
import profile3 from '../../Assests/profilepic.png'; // Replace with actual image path
import profile4 from '../../Assests/profilepic.png'; // Replace with actual image path
import profile5 from '../../Assests/profilepic.png'; // Replace with actual image path
import profile6 from '../../Assests/profilepic.png'; // Replace with actual image path

const reviews = [
  {
    text: "Best biryani I've ever had! Highly recommend.",
    user: "Nayan Mishra",
    profilePic: profile1
  },
  {
    text: "Amazing flavors and great service.",
    user: "Sanskriti Gupta",
    profilePic: profile2
  },
  {
    text: "A must-visit place for all biryani lovers.",
    user: "Aman Yadav",
    profilePic: profile3
  },
  {
    text: "Fantastic food and cozy ambiance.",
    user: "Shudhish Banoudhia",
    profilePic: profile4
  },
  {
    text: "Delicious biryani and wonderful staff.",
    user: "Abhay Diwedi",
    profilePic: profile5
  },
  {
    text: "The best dining experience I've had.",
    user: "Ratnesh Mittal",
    profilePic: profile6
  },
];

const ReviewSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // Function to update visible reviews
    const updateVisibleReviews = () => {
      setVisibleReviews([
        reviews[currentIndex % reviews.length],
        reviews[(currentIndex + 1) % reviews.length],
        reviews[(currentIndex + 2) % reviews.length],
      ]);
    };

    // Update visible reviews initially
    updateVisibleReviews();

    // Set interval to rotate reviews
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => prevIndex + 1);
      updateVisibleReviews(); // Update visible reviews on interval
    }, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="review-section" id="review">
      <h2 className="review-heading">Customer Reviews</h2>
      <p className="review-subheading">Hear what our customers have to say about us.</p>
      <div className="review-cards">
        {visibleReviews.map((review, index) => (
          <div className="review-card" key={index}>
            <p className="review-text">"{review.text}"</p>
            <div className="review-profile">
              <img src={review.profilePic} alt={review.user} className="review-pic" />
              <span> - {review.user}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewSection;
