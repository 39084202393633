import React from 'react'
import './Franchisepage.css'
import FranchiseNav from '../component/Frnachise/FranchiseNav'
import Welcome from '../component/Frnachise/Welcome'
import FranchiseDetails from '../component/Frnachise/FranchiseDetails'
import FranchiseForm from '../component/Frnachise/FranchiseForm'
import FranchiseContact from '../component/Frnachise/FranchiseContact'
import FAQ from '../component/Frnachise/Faq'
export default function Franchisepage() {
  return (
    <>
      <FranchiseNav/>
      <Welcome/>
      <FranchiseDetails/>
      <FranchiseForm/>
      <FAQ/>
      <FranchiseContact/>
    </>
  )
}
