import React, { useEffect, useState } from 'react';


const messages = [
  'Welcome Nawaabs !',
  'Biryani Nawaabs welcomes you',
  'More Profit !',
  'More Growth !',
  'More Income !',
  'Buy your Franchise Now !',
];

const Welcome = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="welcome-section1">
      <div className="welcome-message1">
        {messages[currentMessageIndex]}
      </div>
    </section>
  );
};

export default Welcome;
