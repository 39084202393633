import React from 'react';
import LOGO from '../../Assests/company.png';
import InstagramIcon  from '../../Assests/insta.png'; // Assuming you have an Instagram SVG icon

const Contact = () => {
  return (
    <div className="contact-section" id="contact">
      <div className="contact-left">
        <div className="contact-logo">
          <img src={LOGO} alt="Logo" />
          <p>Biryani Nawaabs</p>
          <p className="copyright">© 2024 Biryani Nawaabs. All rights reserved.</p>
        </div>
      </div>
      <div className="contact-right">
        <div className="contact-details">
          <div className="contact-support">
            <h3>Support</h3>
            <ul>
              <li><a href="#FranchiseDetails">Franchise Details</a></li>
              <li><a href="#FranchiseForm">Franchise Form</a></li>
              <li><a href="#Faqs">FAQs</a></li>
            </ul>
          </div>
          <div className="contact-phone">
            <h3>Phone Number</h3>
            <p>‪+91 70804 62999‬</p>
          </div>
          <div className="contact-email">
            <h3>Email</h3>
            <a href="mailto:Biryaninawaabs@gmail.com">Biryaninawaabs@gmail.com</a>
          </div>
          <div className="contact-address">
            <h3>Address</h3>
            <p>Aliganj<br />Lucknow, Uttar Pradesh</p>
          </div>
        </div>
        <div className="contact-social">
          <p>Follow us on</p>
          <a href="https://www.instagram.com/biryaninawaabs.india?igsh=MWN3MW5jd3M1M3NxZQ==">
            <img src={InstagramIcon} className="instagram-icon" alt="insta logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
