import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';
import ROIimage from '../../Assests/roi.png'
import ROBOimage from '../../Assests/robo.png'
import CHEFimage from '../../Assests/chef.png'
import SUPPORTimage from '../../Assests/support.png'
const WhyWe = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: ['Why We?'],
      typeSpeed: 80,
      backSpeed: 80,
      loop: true,
      showCursor: false,
    };

    const typed = new Typed(typedRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="why-we-section">
      <div className="why-we-content">
        <h1 ref={typedRef}></h1>
        <p>
          Our franchise offers unmatched quality and taste that stands out in the market. Join us and be a part of a culinary revolution!
        </p>
        <Link to='/franchise' className="buy-franchise-button">Buy Franchise</Link>
      </div>
      <div className="why-we-right">
        <div className="card">
          <img src={ROIimage} alt="Card 1" />
          <div className="card-text">High ROI</div>
        </div>
        <div className="card">
          <img src={ROBOimage} alt="Card 2" />
          <div className="card-text">Easy Setup</div>
        </div>
        <div className="card">
          <img src={CHEFimage} alt="Card 3" />
          <div className="card-text">Delicious Recipes</div>
        </div>
        <div className="card">
          <img src={SUPPORTimage} alt="Card 4" />
          <div className="card-text">100% Support</div>
        </div>
      </div>
    </div>
  );
};

export default WhyWe;
